// This is the default state object
const componentsReducerDefaultState = {
    drawerOpen: true
};

export default (state = componentsReducerDefaultState, action ) => {
   switch (action.type) {
       case 'TOGGLE_DRAWER':
           return {
            ...state,
            drawerOpen: !state.drawerOpen
           }
       
        default:
           return state;
   }
};