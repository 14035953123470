import React, { useState } from "react";
import {
    Button,
    Card,
    Checkbox,
    Divider,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: "10vh",
        marginTop: theme.spacing(4),
    },
    center: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
    },
    title: {
        padding: theme.spacing(1, 2),
    },
    button: {
        fontSize: "24px",
        width: "260px",
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
    },
    successBtn: {
        backgroundColor: "#4caf50",
        color: "white",
    },
    palletInfo: {
        marginTop: theme.spacing(2),
    },
    table: {
        height: "30vh",
    },
}));

export default function Pallet() {
    const classes = useStyles();
    const [palletInfo, setPalletInfo] = useState({});

    const handlePallet = (e) => {
        const pallet = e.target.value;

        if (pallet.length === 12 && pallet.substring(0, 2) === "PA") {
            const token = localStorage.getItem("token");
            fetch("https://maracle-api.saphera.com/api/v1/assembly/pallet?barcode=" + pallet, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        const err = new Error(res.error);
                        throw err;
                    }
                })
                .then((data) => {
                    setPalletInfo(data);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const handleDate = (d) => {
        const date = d.replace("T", " ");
        return date;
    };
    return (
        <div className={classes.root}>
            <Typography variant="h5">Pallet Station</Typography>
            <div className={classes.center}>
                <TextField
                    id="inputField"
                    autoFocus
                    variant="outlined"
                    placeholder="Scan Pallet"
                    className={classes.controlSheet}
                    inputProps={{
                        style: {
                            backgroundColor: "white",
                            fontSize: "24px",
                            textAlign: "center",
                            width: "260px",
                        },
                    }}
                    onChange={handlePallet}
                />
            </div>

            {palletInfo.palletId && (
                <Card className={classes.palletInfo}>
                    <Typography variant="h6" className={classes.title}>
                        Pallet - {palletInfo.palletId}
                    </Typography>
                    <Divider />
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <strong>Total Boxes</strong>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{palletInfo.totalBoxes}</Typography>
                                </TableCell>
                                <TableCell>Total Orders</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{palletInfo.totalOrders}</Typography>
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                            
                        </TableRow> */}
                            <TableRow>
                                <TableCell>Total Weight</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{palletInfo.totalWeight}</Typography>
                                </TableCell>
                                <TableCell>Total Planners</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{palletInfo.totalPlanners}</Typography>
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                            
                        </TableRow> */}
                            <TableRow>
                                <TableCell>Pallet Title</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{palletInfo.palletTitle}</Typography>
                                </TableCell>
                                <TableCell>Pallet Release Date</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">
                                        {handleDate(palletInfo.palletReleaseDateUtc)}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Rail Date</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{palletInfo.railDateUtc}</Typography>
                                </TableCell>
                                <TableCell>Ship Method</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">{palletInfo.shipMethod}</Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Rail Shipped Date</TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">
                                        {handleDate(palletInfo.palletShippedDateUtc)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    Pallet Full
                                    <Checkbox disabled checked={palletInfo.palletFull} />
                                </TableCell>
                                <TableCell>
                                    Pallet Shipped
                                    <Checkbox disabled checked={palletInfo.palletShipped} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            )}
            {palletInfo.palletId && (
                <div className={classes.center}>
                    <Button
                        // disabled={!status.success}
                        variant="contained"
                        className={clsx(classes.button, classes.successBtn)}
                        // onClick={handleButtonClicked}
                    >
                        Ship Pallet
                    </Button>
                </div>
            )}
            {palletInfo.palletId && (
                <div className={classes.center}>
                    <TableContainer component={Paper} className={classes.table}>
                        <Typography variant="h6" className={classes.title}>
                            Boxes
                        </Typography>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order #</TableCell>
                                    <TableCell>Planner ID</TableCell>
                                    <TableCell>Box ID</TableCell>
                                    <TableCell>Planner Name</TableCell>
                                    <TableCell>Weight</TableCell>
                                    <TableCell>Planner Status</TableCell>
                                    <TableCell>Ship Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {palletInfo.items.map((i) => (
                                    <TableRow>
                                        <TableCell>{i.orderId}</TableCell>
                                        <TableCell>{i.plannerId}</TableCell>
                                        <TableCell>{i.boxId}</TableCell>
                                        <TableCell>{i.plannerName}</TableCell>
                                        <TableCell>{i.boxWeight}</TableCell>
                                        <TableCell>{i.plannerStatus}</TableCell>
                                        <TableCell>{i.shipType}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
}
