import { createStore } from 'redux';
import componentsReducer from './componentsReducer';


export default () => {
    const store = createStore(
        // use combineReducer when there's many reducers
        // combineReduers({
    componentsReducer
        // })
    ,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    return store;
};