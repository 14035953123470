const signonBackgrounds = [
    {
        image: "/images/bg1.jpg",
        alt: "",
    },
    {
        image: "/images/bg2.jpg",
        alt: "",
    },
];

// Get a random signon background form the project settings file
export function RandomSignonBackground() {
    return getRandomBackground(signonBackgrounds);
}

// Randomly pick an image out of an array of images
function getRandomBackground(inList) {
    const index = Math.floor(Math.random() * inList.length);
    return inList[index].image;
}
