const white = "#FFFFFF";

export default {
    primary: {
        light: "#757ce8",
        main: "#3f50b5",
        dark: "#002884",
        contrastText: "#fff",
    },
    secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: white,
    },
    // text: {
    //     primary: colors.blueGrey[900],
    //     secondary: colors.blueGrey[600],
    //     link: colors.blue[600],
    // },
};
