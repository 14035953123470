import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import CopyRight from "../components/CopyRight";
import { RandomSignonBackground } from "./RandomBackgrounds";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: "url(" + RandomSignonBackground() + ")",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.icon,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login(props) {
    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (name === "email") {
            setEmail(value);
        } else {
            setPassword(value);
        }
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const credentials = { email, password };
        // fetch("/api/authenticate", {
        fetch("https://maracle-api.saphera.com/api/v1/authenticate", {
            method: "POST",
            body: JSON.stringify(credentials),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                console.log(res.status);
                if (res.status === 200) {
                    return res.json();
                } else {
                    console.log("throwing!");
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                localStorage.setItem("token", data.token);
                props.history.push("/crm");
            })
            .catch((err) => {
                console.error(err);
                alert("Error logging in please try again");
            });
    };
    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOpenIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={onSubmit}>
                        <TextField
                            variant="outlined"
                            type="email"
                            name="email"
                            margin="normal"
                            fullWidth
                            label="Enter email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            required
                            onChange={handleInputChange}
                            id="email"
                        />
                        <TextField
                            variant="outlined"
                            type="password"
                            name="password"
                            margin="normal"
                            fullWidth
                            label="Enter password"
                            autoComplete="current-password"
                            autoFocus
                            value={password}
                            onChange={handleInputChange}
                            required
                            id="password"
                        />
                        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            value="submit"
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/" target="_blank" variant="body2">
                                    {"Don't have an account?"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <CopyRight />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
