import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import componentsStore from "../components/Portal/redux/componentsStore";
import { drawerToggle } from "../components/Portal/redux/componentsActions";
// import { Link } from "react-router-dom";

// import UserMenu from "../components/Portal/UserMenu";
import Menu from "./menu/Menu";

import {
    AppBar,
    // Badge,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    makeStyles,
    Toolbar,
    Typography,
    useTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/Notifications";

import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    logout: {
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const store = componentsStore();

store.subscribe(() => {
    console.log(store.getState());
});

// export default function
const Header = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    const toggleDraw = props.open;
    const handleDrawerOpen = props.handleDrawerOpen;

    const logout = (event) => {
        event.preventDefault();

        localStorage.clear();
        history.push("/login");
    };

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: toggleDraw,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: toggleDraw,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Maracle CRM
                    </Typography>
                    {/* <IconButton color="inherit" component={Link} to="/crm/alerts">
                        <Badge badgeContent={1} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
                    {/* <UserMenu /> */}
                    <Button className={classes.logout} onClick={logout}>
                        <Typography variant="subtitle1">Logout</Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: toggleDraw,
                    [classes.drawerClose]: !toggleDraw,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: toggleDraw,
                        [classes.drawerClose]: !toggleDraw,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerOpen}>
                        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <Menu />
                </List>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        open: state.drawerOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleDrawerOpen: () => {
            dispatch(drawerToggle());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
