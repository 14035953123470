import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    containerBlock: {
        alignItems: 'center',
        color: '#4d4d4d',
        margin: '0 auto',
        left: '0',
        right: '0',
        padding: '20px',
        position: 'absolute',
        textAlign: 'center',
        transform: 'translateY(-50%)',
        top: '50%',
    },
    subtitleText: {
        color: '#4d4d4d',
    },
}));

export default function Error404() {
    const classes = useStyles();

    return (
        <div className={classes.containerBlock}>
            <Grid>
                <Grid item md>
                    <img src="/shrug.png" alt="Error 404" />
                </Grid>
                <Grid item md>
                    <Typography component="h1" variant="h3">
                        ERROR 404 ¯\_(ツ)_/¯
                    </Typography>
                    <Typography variant="h4" className={classes.subtitleText}>
                        Did you <a href='/login'>Sign in</a>? Or return to <a href='/'>Home</a>.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
};