import React from // , { useState }
"react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Divider, Icon, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const Menu = () => {
    // const [selectedIndex, setSelectedIndex] = useState(0);

    // const handleListItemClick = (e, index) => {
    //     setSelectedIndex(index);
    // };

    return (
        <div>
            <List>
                <ListItem
                    button={true}
                    component={Link}
                    to="/crm"
                    // selected={selectedIndex === 0}
                    // onClick={(e) => handleListItemClick(e, 0)}
                >
                    <ListItemIcon>
                        <Icon>dashboard</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <Divider />
                <ListItem
                    button={true}
                    component={Link}
                    to="/crm/bwprinting"
                    // selected={selectedIndex === 1}
                    // onClick={(e) => handleListItemClick(e, 1)}
                >
                    <ListItemIcon>
                        <Icon>invert_colors</Icon>
                    </ListItemIcon>
                    <ListItemText primary="B&W Printing" />
                </ListItem>
                <ListItem
                    button={true}
                    component={Link}
                    to="/crm/colourprinting"
                    // selected={selectedIndex === 2}
                    // onClick={(e) => handleListItemClick(e, 2)}
                >
                    <ListItemIcon>
                        <Icon>palette</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Colour Printing" />
                </ListItem>
                <Divider />
                <ListItem
                    button={true}
                    component={Link}
                    to="/crm/picker"
                    // selected={selectedIndex === 3}
                    // onClick={(e) => handleListItemClick(e, 3)}
                >
                    <ListItemIcon>
                        <Icon>find_in_page</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Picker" />
                </ListItem>
                <Divider />
                <ListItem
                    button={true}
                    component={Link}
                    to="/crm/pallet"
                    // selected={selectedIndex === 4}
                    // onClick={(e) => handleListItemClick(e, 4)}
                >
                    <ListItemIcon>
                        <Icon>local_shipping</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Pallet" />
                </ListItem>
            </List>
        </div>
    );
};

// export default Menu;
const mapStateToProps = (state, ownProps) => {
    return {
        open: state.drawerOpen,
    };
};

export default connect(mapStateToProps, null)(Menu);
