import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export default function withAuth(ComponentToProtect) {
    return class extends Component {
        constructor() {
            super();
            this.state = {
                loading: true,
                redirect: false,
            };
        }

        componentDidMount() {
            console.log("WITHAUTH");
            const token = localStorage.getItem("token");

            fetch("https://maracle-api.saphera.com/api/v1/checkToken", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({ loading: false });
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.setState({ loading: false, redirect: true });
                });
        }

        render() {
            const { loading, redirect } = this.state;
            if (loading) {
                return null;
            }
            if (redirect) {
                return <Redirect to="/login" />;
            }
            return (
                <React.Fragment>
                    <ComponentToProtect {...this.props} />
                </React.Fragment>
            );
        }
    };
}

// import React, {  useState, useEffect } from 'react';
// import { Redirect } from 'react-router-dom';

// function withAuth(ProtectedComponent, props) {
//     // const [loading, setLoading] = useState(true);
//     // const [redirect, setRedirect] = useState(false);
//     console.log(props);

//     useEffect(() => {
//         console.log('WITHAUTH')
//         fetch('/api/checkToken')
//             .then(res => {
//                 if (res.status === 200) {
//                     // setLoading(false);
//                 }
//                 else {
//                     const error = new Error(res.error);
//                     throw error;
//                 }
//             })
//             .catch(err => {
//                 console.error(err);
//                 // setLoading(false);
//                 // setRedirect(true);
//             });
//     }, []);

//     // if (loading) {
//     //     return null;
//     // }
//     // if (redirect) {
//     //     return <Redirect to="/login" />;
//     // }
//     return (
//         <React.Fragment>
//             <ProtectedComponent {...props} />
//         </React.Fragment>
//     )
// }

// export default withAuth;
