import React from "react";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    copyright: {
        margin: theme.spacing(4, 0, 6),
    },
}));

export default function CopyRight() {
    const classes = useStyles();

    return (
        <div className={classes.copyright}>
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                {new Date().getFullYear()}{" "}
                <Link target="_blank" color="inherit" href="https://maracleinc.com/">
                    Maracle Inc.
                </Link>
                <br />
                {"Software By "}
                <Link target="_blank" color="inherit" href="https://www.saphera.com/">
                    Saphera Software
                </Link>
            </Typography>
        </div>
    );
}
