import React, { useState } from "react";
import { Button, Container, Grid, makeStyles, TextField, Typography, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginTop: theme.spacing(2),
    },
    root: {
        // marginTop: theme.spacing(8),
        margin: theme.spacing(0, "auto"),
        width: "100%",

        // display: "block",
        // display: "flex",
        // justifyContent: "center",
        // height: "80vh",
        // backgroundColor: "white",
        padding: theme.spacing(1, 0),
    },
    textField: {
        // display: "block",
        padding: theme.spacing(0, 2),
    },
    fieldSpacing: {
        // margin: theme.spacing(8, 0),
        padding: theme.spacing(3, 0),
    },
    status: {
        backgroundColor: "white",
        border: "2px #4caf50 solid",
        borderRadius: "5px",
        padding: theme.spacing(2, 0),
        color: "#4caf50",
        fontSize: "26px",
        fontWeight: 500,
        width: "280px",
    },
    inputRoot: {
        "&$disabled": {
            // color: "#4caf50",
            // color: "black",
            // color: "#424242",
            color: "#3f50b5",
        },
    },
    disabled: {},
    button: {
        // width: "320px",
        width: "92.5%",
        // margin: theme.spacing(0, 2),
        padding: theme.spacing(2, 0),
    },
    error: {
        border: "1px  solid",
        borderRadius: "5px",
        color: "#f44336",
        fontSize: "28px",
        width: "50%",
        margin: theme.spacing(4, "auto", 6),
        textAlign: "center",
        padding: theme.spacing(5),
    },
}));

export default function PrintingStation(props) {
    const classes = useStyles();
    const theme = useTheme();
    // const [controlSheetState, setControlSheetState] = useState(false);

    const [controlSheet, setControlSheet] = useState("");

    const handleControlSheet = (e) => {
        setControlSheet(e.target.value);
    };

    return (
        <div style={{ marginTop: theme.spacing(4) }}>
            <Typography variant="h5" className={classes.pageTitle}>
                {/* <h1 className={classes.pageTitle}> */}
                {props.title}
                {/* </h1> */}
            </Typography>

            <Container className={classes.root}>
                <Grid container className={classes.fieldSpacing}>
                    <Grid item md={12} sm={12} align="center">
                        <TextField
                            autoFocus
                            label="Control Sheet"
                            variant="outlined"
                            placeholder="Scan Control Sheet"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                style: {
                                    // backgroundColor: "white",
                                    fontSize: "24px",
                                    textAlign: "center",
                                    width: "260px",
                                },
                            }}
                            onChange={handleControlSheet}
                            value={controlSheet}
                        />
                    </Grid>
                </Grid>
                {controlSheet === "true" && (
                    <>
                        <Grid container className={classes.fieldSpacing}>
                            <Grid item md={12} sm={12} align="center">
                                <TextField
                                    disabled
                                    label="Status"
                                    variant="outlined"
                                    defaultValue="READY"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            fontSize: "24px",
                                            fontWeight: 500,
                                            textAlign: "center",
                                            width: "260px",
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.fieldSpacing}>
                            <Grid item md={6} sm={6} align="center">
                                <TextField
                                    label="Quantity Printed"
                                    className={classes.textField}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="4500"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { paddingLeft: "20px" },
                                    }}
                                    inputProps={{
                                        style: {
                                            width: "300px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} align="center">
                                <TextField
                                    label="School Pages"
                                    fullWidth
                                    className={classes.textField}
                                    variant="outlined"
                                    placeholder="4500"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { paddingLeft: "20px" },
                                    }}
                                    inputProps={{
                                        style: {
                                            width: "300px",
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.fieldSpacing}>
                            <Grid item md={6} sm={6} align="center">
                                <TextField
                                    label="Label Count"
                                    fullWidth
                                    className={classes.textField}
                                    variant="outlined"
                                    placeholder="2"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { paddingLeft: "20px" },
                                    }}
                                    inputProps={{
                                        style: {
                                            width: "300px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} align="center">
                                <Button variant="contained" className={classes.button} color="primary" fullWidth>
                                    Print Labels
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
                {controlSheet === "false" && (
                    <>
                        <Grid container direction="column">
                            <Grid item md={12} sm={12} align="center" className={classes.fieldSpacing}>
                                <TextField
                                    disabled
                                    label="Status"
                                    variant="outlined"
                                    defaultValue="CANCELLED"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            fontSize: "24px",
                                            fontWeight: 500,
                                            textAlign: "center",
                                            width: "260px",
                                        },
                                    }}
                                />
                            </Grid>
                            {/* <Grid item align="center" md={12} className={classes.fieldSpacing}> */}
                            <div className={classes.error}>
                                {/* <Typography variant="h"> */}
                                DO NOT PRINT ORDER
                                {/* </Typography> */}
                            </div>
                            {/* </Grid> */}
                        </Grid>
                    </>
                )}
            </Container>
        </div>
    );
}
