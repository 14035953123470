import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Box, Button, CircularProgress, LinearProgress, makeStyles, TextField, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: "10vh",
        marginTop: theme.spacing(4),
    },
    center: {
        display: "flex",
        justifyContent: "center",
        marginTop: "5vh",
        // marginTop: theme.spacing(3),
    },
    status: {
        textAlign: "center",
    },
    successMessage: { color: "#4caf50" },
    notSuccessMessage: {
        // color: "#e53935",?
        color: "#ff5722",
        fontSize: "40px",
        fontWeight: "bold",
    },
    notSuccessMessage2: { color: "#e53935" },
    button: {
        fontSize: "24px",
        // padding: theme.spacing(1, 5),
        // color: "white",
        width: "260px",
        fontWeight: 500,
    },
    successBtn: {
        backgroundColor: "#4caf50",
        color: "white",
    },
    notSuccessBtn: {
        // backgroundColor: "#e53935",
    },
    card: {
        // backgroundColor: "white",
        backgroundColor: "#ffd54f",

        border: "1px solid #eeeeee",
        borderRadius: "5px",
        padding: theme.spacing(3),
    },
    icon: {
        fontSize: 50,
        marginRight: theme.spacing(1),
        // color: "#fbc02d",
    },
}));

export default function Picker() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState({});
    const [scanField, setScanField] = useState("");

    const [controlSheetNumber, setControlSheetNumber] = useState("");

    const [countdown, setCountdown] = useState(5);
    const [progress, setProgess] = useState(0);

    const [disabledScan, setDisabledScan] = useState(false);

    useEffect(() => {
        if (controlSheetNumber.length === 12 && controlSheetNumber.substring(0, 2) === "DA") {
            setLoading(true);
            const token = localStorage.getItem("token");
            fetch("https://maracle-api.saphera.com/api/v1/assembly/picker?barcode=" + controlSheetNumber, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setScanField(controlSheetNumber);
                        return res.json();
                    } else {
                        const err = new Error(res.error);
                        throw err;
                    }
                })
                .then((data) => {
                    setDisabledScan(true);
                    setLoading(false);
                    setStatus(data);
                    setControlSheetNumber("");
                    if (!data.success) {
                        setDisabledScan(true);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
            // document.getElementById("inputField").focus();
        }
    }, [controlSheetNumber]);

    //V1 to call the 2nd api on a successfull 1st call
    // const handleButtonClicked = () => {
    //     const token = localStorage.getItem("token");
    //     fetch(`https://maracle-api.saphera.com/api/v1/planners/${status.plannerId}/assemble`, {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((res) => {
    //             setControlSheetNumber("");
    //             setStatus({});
    //             document.getElementById("inputField").focus();

    //             if (res.status !== 200) {
    //                 const err = new Error(res.error);
    //                 throw err;
    //             }
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // };

    //V2 call the 2nd api after a 5 second countdown
    useEffect(() => {
        let timer;
        if (status.success) {
            let timeleft = 20;
            let count = 1;
            timer = setInterval(() => {
                if (timeleft > 100 && count > 5) {
                    clearInterval(timer);
                    handle2ndCallOnSuccess(timer);
                    return;
                }
                setProgess(progress + timeleft);
                setCountdown(countdown - count);
                timeleft += 20;
                count += 1;
            }, 1000);
        } else {
            setProgess(0);
            setCountdown(5);
        }

        const handle2ndCallOnSuccess = (timer) => {
            console.log("hello");
            const token = localStorage.getItem("token");
            fetch(`https://maracle-api.saphera.com/api/v1/planners/${status.plannerId}/assemble`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    setControlSheetNumber("");
                    setStatus({});
                    setProgess(0);
                    setCountdown(5);
                    document.getElementById("inputField").focus();
                    if (res.status !== 200) {
                        const err = new Error(res.error);
                        throw err;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
            clearInterval(timer);
            setDisabledScan(false);
        };

        return () => {
            clearInterval(timer);
        };
    }, [status.success]);

    const handleButtonClicked = () => {
        setScanField("");
        setStatus({});
        setDisabledScan(false);
    };
    useEffect(() => {
        if (!disabledScan) {
            document.getElementById("inputField").focus();
        }
    }, [disabledScan]);

    useEffect(() => {
        if (status.success !== undefined && !status.success) {
            setControlSheetNumber("");
            document.getElementById("inputField").focus();
        }
    }, [status]);

    return (
        <div className={classes.root}>
            <Typography variant="h5">Picker Station</Typography>
            <div className={classes.center}>
                <TextField
                    id="inputField"
                    autoFocus
                    disabled={disabledScan}
                    variant="outlined"
                    placeholder="Scan Control Sheet"
                    className={classes.controlSheet}
                    inputProps={{
                        style: {
                            backgroundColor: "white",
                            fontSize: "24px",
                            textAlign: "center",
                            width: "260px",
                        },
                    }}
                    onChange={(e) => setControlSheetNumber(e.target.value)}
                    value={controlSheetNumber}
                />
            </div>
            {loading && (
                <div className={classes.center} style={{ alignItems: "center" }}>
                    <CircularProgress className={classes.loading} />
                </div>
            )}
            {status.success !== undefined && (
                <>
                    <div className={clsx(classes.center, classes.status)}>
                        <div className={!status.success ? classes.card : undefined}>
                            <Typography variant="h4" style={{ marginBottom: "10px" }}>
                                {scanField}
                            </Typography>
                            <Typography variant="subtitle1">Order ID: {status.orderId}</Typography>
                            <Typography style={{ fontSize: "1.25rem", fontWeight: 400 }}>{status.school}</Typography>
                            <Typography variant="subtitle1" style={{ marginBottom: "40px" }}>
                                {status.schoolAddress}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                ORDER STATUS
                            </Typography>
                            <Typography
                                variant="h5"
                                className={status.success ? classes.successMessage : classes.notSuccessMessage}
                                style={{ marginBottom: "30px" }}
                            >
                                {!status.success && <ErrorIcon className={classes.icon} />}
                                {status.orderStatus}
                            </Typography>
                            {status.plannerStatus !== "" ? (
                                <>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        PLANNER STATUS
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        className={status.success ? classes.successMessage : classes.notSuccessMessage2}
                                    >
                                        {status.plannerStatus}
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="h5" className={classes.notSuccessMessage}>
                                    {"This planner cannot be assembled".toUpperCase()}
                                </Typography>
                            )}
                        </div>
                    </div>

                    {!status.success ? (
                        <div className={classes.center}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleButtonClicked}
                            >
                                RESET
                            </Button>
                        </div>
                    ) : (
                        <div style={{ marginTop: "5vh" }}>
                            <Box alignItems="center" style={{ width: "40%", margin: "0 auto" }}>
                                <Box mr={1} style={{ width: "100%", margin: "auto" }}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Box>
                                <Box style={{ width: "100%", margin: "10px auto 0 ", textAlign: "center" }}>
                                    <Typography variant="body2" color="textSecondary">
                                        status update in {Math.round(countdown)}...
                                    </Typography>
                                </Box>
                            </Box>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
