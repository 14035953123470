import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import {
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import Moment from "moment";

import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    divider: {
        margin: theme.spacing(4, 0),
    },
    miniStatBox: {
        display: "flex",
        padding: theme.spacing(3, 0),
    },
    icon: {
        fontSize: "40px",
    },
    leftPadding: {
        paddingLeft: theme.spacing(1),
    },
    card1: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    card2: {
        color: "#212121",
    },
    pieTitle: {
        fontSize: "20px",
        padding: theme.spacing(2, 0, 0, 2),
    },
    pie: { margin: "2px 0"},
    logCard: {
        height: "505px",
        overflowY: "scroll",
        // "&::-webkit-scrollbar": {
        //     display: "none",
        // },
    },
    plannerCard: {
        height: "100%",
        "&:hover": {
            overflowY: "scroll"
        },
        "&::-webkit-scrollbar": {
            display: "none",
        },
    }
}));
export default function Dashboard() {
    const classes = useStyles();

    const flaggedPlannerStatus = [
        {
            "status": "Planner Coiled",
            "quantity": 0
        },
        {
            "status": "School Pages Submitted",
            "quantity": 0
        },
        {
            "status": "School Pages Printed",
            "quantity": 0
        },
        {
            "status": "Assembling Planner",
            "quantity": 0
        }
    ]

    const setShipPieState = {
        series: [],
        
    }

    const plannerAssemblyState = {
        options: {
            stroke: {
                curve: "smooth"
            },
            chart: {
                id: "basic-bar",
                zoom: {
                    type: "x",
                    enabled: true,
                    // autoScaleYaxis: true,
                },
                toolbar: {
                    autoSelected: "zoom",
                },
            },
            colors: ["#3f50b5"],
            tooltip: {
                enabled: true,
                x: {
                    show: true,
                    format: 'dd MMM yyyy'
                }
            },
            dataLabels: {
                enabled: true,
            },
            title: {
                text: "Planner Assembly Progress",
                align: "left",
                style: {
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "regular",
                },
            },
            xaxis: {
                title: {
                    text: "Date",
                    style: {
                        fontFamily: "Roboto",
                        fontSize: "16px",
                    },
                },

                type: "datetime",
            },
            yaxis: {
                title: {
                    text: "Units",
                    style: {
                        fontFamily: "Roboto",
                        fontSize: "16px",
                    },
                },
            },
        },
        series: [
            {
                name: "series-1",
                data: [256, 276, 267, 268, 253, 256, 276, 267, 268, 253, 287],
            },
        ],
    };

    const thisMonthTemplate = {
        series: [
            {
                name: "Boxes",
                data: [256, 276, 267, 268, 253, 256, 276, 267, 268, 253, 287],
            },
        ],
        options: {
            chart: {
                type: "area",
            },
            title: {
                text: "Total Boxes",
                align: "left",
                style: {
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "regular",
                },
            },
            colors: ["#3f50b5"],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                title: {
                    text: "Date",
                    style: {
                        fontFamily: "Roboto",
                        fontSize: "16px",
                    },
                },
                type: "datetime",
                categories: [
                    "2018-09-19",
                    "2018-09-20",
                    "2018-09-21",
                    "2018-09-22",
                    "2018-09-23",
                    "2018-09-24",
                    "2018-09-25",
                    "2018-09-26",
                    "2018-09-27",
                    "2018-09-28",
                    "2018-09-29",
                ],
            },
            yaxis: {
                title: {
                    text: "Boxes",
                    style: {
                        fontFamily: "Roboto",
                        fontSize: "16px",
                    },
                },
            },
            tooltip: {
                x: {
                    format: "dd MMM yyyy",
                },
            },
        },
    };
    const [dateRange, setDateRange] = useState([null, null]);

    const date = new Date();
    const [plannerStatusStates, setPlannerStatusStates] = useState([])
    const [orderStatusStats, setOrderStatusStats] = useState([]);
    const [shipPie, setShipPie] = useState({});
    const [thisMonth, setThisMonth] = useState(thisMonthTemplate)
    const [plannerAssembly, setPlannerAssembly] = useState(plannerAssemblyState)

    useEffect(() => {
        const token = localStorage.getItem("token");
        //enable cors
        fetch("https://maracle-api.saphera.com/api/v1/graphs/plannerProductionStats", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => res.json())
        .then(data => {
            let reducedArr = {}
            let arr = [...flaggedPlannerStatus, ...data]

            arr.forEach(item => {
                reducedArr[item.status] = (reducedArr[item.status] + item.quantity) || item.quantity
            })

            setPlannerStatusStates(reducedArr)
        }).catch(err => console.error(err))

        fetch("https://maracle-api.saphera.com/api/v1/graphs/orderStatusStats", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setOrderStatusStats(data);
                let shipped = {
                    ...shipPie,
                    series: [
                        data.find((s) => s.status === "Order Shipped Direct").total,
                        data.find((s) => s.status === "Order Shipped Zone Skip").total,
                    ],
                    options: {
                        chart: {
                            type: "donut",
                        },
                        labels: ["Direct", "Zone Skip"],
                        legend: {
                            position: "bottom",
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        total: {
                                            show: true,
                                            label: "Total",
                                            formatter: (w) => {
                                                return w.globals.seriesTotals.reduce((a, b) => {
                                                    return a + b;
                                                }, 0);
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        responsive: [
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 200,
                                    },
                                    legend: {
                                        position: "bottom",
                                    },
                                },
                            },
                        ],
                    },
                };
                setShipPie(shipped);
            });

        fetch("https://maracle-api.saphera.com/api/v1/graphs/plannerAssemblyStats", {
            headers: {
                Authorization: `Bearer ${token}`,
                
            },
        }).then(res => res.json())
        .then(data => {
            const dates = data.map(stat => stat.orderDate.split("T")[0])
            setPlannerAssembly({
                ...plannerAssembly,
                series: [
                    {
                        name: "Planners",
                        data: data.map(stat => stat.storeOrderDetailsQuantity)
                    }
                ],
                options: {
                    
                    ...plannerAssembly.options,
                    xaxis: {
                        type: "datetime",
                        categories: dates
                    },
                }
            })
        })

        fetch(`https://maracle-api.saphera.com/api/v1/graphs/totalBoxesStats`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => res.json())
        .then(data => {
            setThisMonth({
                ...thisMonth,
                series: [
                    {
                        name: "Boxes",
                        data: data.map(stat => stat.boxes)
                    }
                ],
                options: {
                    ...thisMonth.options,
                    xaxis: {
                        type: "datetime",
                        categories: data.map(stat => stat.orderDate.split("T")[0])
                    }
                }
            })
        }).catch(err => console.error(err))

        let endDate = Moment().format("YYYY-MM-DD")
        let month = Moment().get('month') - 1
        let year = Moment().get('year')
        let date = Moment().get('date')
        if (month - 1 < 0) {
            year--;
            month = 12
        }
        let startDate = Moment().set({
            'year': year,
            'month': month,
            'date': date
        }).format("YYYY-MM-DD") 

        setDateRange([startDate, endDate])
        
    }, []);

    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            const start = Moment(dateRange[0]).format("YYYY-MM-DD");
            const end = Moment(dateRange[1]).format("YYYY-MM-DD");
            const token = localStorage.getItem("token");
            fetch(`https://maracle-api.saphera.com/api/v1/graphs/plannerProductionStats?startDate=${start}&endDate=${end}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => res.json())
            .then(data => {
                let reducedArr = {}
                let arr = [...flaggedPlannerStatus, ...data]

                arr.forEach(item => {
                    reducedArr[item.status] = (reducedArr[item.status] + item.quantity) || item.quantity
                })

                setPlannerStatusStates(reducedArr)
            }).catch(err => console.error(err))
        }
    }, [dateRange])

    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            const start = Moment(dateRange[0]).format("YYYY-MM-DD");
            const end = Moment(dateRange[1]).format("YYYY-MM-DD");
            const token = localStorage.getItem("token");
            fetch(`https://maracle-api.saphera.com/api/v1/graphs/orderStatusStats?startDate=${start}&endDate=${end}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    //sets the dated result in the series to be re set
                    setOrderStatusStats(data);
                    setShipPie({
                        ...shipPie,
                        series: [
                            data.find((s) => s.status === "Order Shipped Direct").total,
                            data.find((s) => s.status === "Order Shipped Zone Skip").total,
                        ],
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: ["Direct", "Zone Skip"],
                            legend: {
                                position: "bottom",
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        labels: {
                                            show: true,
                                            total: {
                                                show: true,
                                                label: "Total",
                                                formatter: (w) => {
                                                    return w.globals.seriesTotals.reduce((a, b) => {
                                                        return a + b;
                                                    }, 0);
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: 200,
                                        },
                                        legend: {
                                            position: "bottom",
                                        },
                                    },
                                },
                            ],
                        },
                    })
                });
        }
    }, [dateRange]);
    
    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            const start = Moment(dateRange[0]).format("YYYY-MM-DD");
            const end = Moment(dateRange[1]).format("YYYY-MM-DD");
            const token = localStorage.getItem("token");

            fetch(`https://maracle-api.saphera.com/api/v1/graphs/plannerAssemblyStats?startDate=${start}&endDate=${end}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(res => res.json())
            .then(data => {
                const dates = data.map(stat => stat.orderDate.split("T")[0])
                setPlannerAssembly({
                    ...plannerAssembly,
                    series: [
                        {
                            name: "Planners",
                            data: data.map(stat => stat.storeOrderDetailsQuantity)
                        }
                    ],
                    options: {
                        
                        ...plannerAssembly.options,
                        xaxis: {
                            type: "datetime",
                            categories: dates
                        },
                    }
                })
            })


            
        }
    }, [dateRange]);

    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            const start = Moment(dateRange[0]).format("YYYY-MM-DD");
            const end = Moment(dateRange[1]).format("YYYY-MM-DD");
            const token = localStorage.getItem("token");

            fetch(`https://maracle-api.saphera.com/api/v1/graphs/totalBoxesStats?startDate=${start}&endDate=${end}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(res => res.json())
            .then(data => {
                setThisMonth({
                    ...thisMonth,
                    series: [
                        {
                            name: "Boxes",
                            data: data.map(stat => stat.boxes)
                        }
                    ],
                    options: {
                        ...thisMonth.options,
                        xaxis: {
                            type: "datetime",
                            categories: data.map(stat => stat.orderDate.split("T")[0])
                        }
                    }
                })
            }).catch(err => console.error(err))
            
        }

    }, [dateRange])

    return (
        <div className={classes.root}>
            <Grid container justify="space-between">
                <Grid item md={3}>
                    <Typography variant="h5">Maracle Dashboard</Typography>
                    <Typography variant="subtitle1">{Moment().format("dddd, MMMM Do, yyyy")}</Typography>
                </Grid>

                <Grid item md={4}>
                    <LocalizationProvider dateAdapter={MomentUtils}>
                        <DateRangePicker
                            startText="Start Date"
                            endText="End Date"
                            value={dateRange}
                            onChange={(newValue) => setDateRange(newValue)}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField {...startProps} />
                                    <DateRangeDelimiter> - </DateRangeDelimiter>
                                    <TextField {...endProps} />
                                </>
                            )}
                            maxDate={date}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
                <Grid item md={4} sm={12} xs={12}>
                    <Card className={classes.card1}>
                        <CardContent className={classes.miniStatBox}>
                            <Grid container alignItems="center">
                                <Grid item md={3} sm={2} align="center">
                                    <DescriptionIcon className={classes.icon} />
                                </Grid>

                                <Grid item className={classes.leftPadding}>
                                    <Typography variant="h4" display="inline">
                                        {orderStatusStats.find((s) => s.status === "Order Entered") &&
                                            orderStatusStats.find((s) => s.status === "Order Entered").total}
                                    </Typography>{" "}
                                    <Typography variant="h6" display="inline">
                                        Orders
                                    </Typography>
                                    <Typography variant="body2">Orders Entered</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <Card className={classes.card2}>
                        <CardContent className={classes.miniStatBox}>
                            <Grid container alignItems="center">
                                <Grid item md={3} sm={2} align="center">
                                    <PrintIcon className={classes.icon} />
                                </Grid>
                                <Grid item className={classes.leftPadding}>
                                    <Typography variant="h4" display="inline">
                                        {orderStatusStats.find((s) => s.status === "Assembling Planner") &&
                                            orderStatusStats.find((s) => s.status === "Assembling Planner").total}
                                    </Typography>{" "}
                                    <Typography variant="h6" color="textSecondary" display="inline">
                                        Orders
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        In Assembly
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <Card className={classes.card2}>
                        <CardContent className={classes.miniStatBox}>
                            <Grid container alignItems="center">
                                <Grid item md={3} sm={2} align="center">
                                    <LocalShippingIcon className={classes.icon} />
                                </Grid>
                                <Grid item className={classes.leftPadding}>
                                    <Typography variant="h4" display="inline">
                                        {orderStatusStats.find((s) => s.status === "Order Shipped Direct") &&
                                            orderStatusStats.find((s) => s.status === "Order Shipped Zone Skip") &&
                                            orderStatusStats.find((s) => s.status === "Order Shipped Direct").total +
                                                orderStatusStats.find((s) => s.status === "Order Shipped Zone Skip")
                                                    .total}
                                    </Typography>{" "}
                                    <Typography variant="h6" color="textSecondary" display="inline">
                                        Orders
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Shipped (Direct & Zone)
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container spacing={2}>
                    <Grid item xl={2} lg={6} md={6} sm={12} xs={12}>
                        <TableContainer component={Card} className={classes.plannerCard}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} align="center">Currently in production</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Planner Status</TableCell>
                                        <TableCell align="center">Total Planners</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.entries(plannerStatusStates).map(([status, quantity], index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{status}</TableCell>
                                                    <TableCell align="center">{quantity}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
                        <Card style={{height: "100%"}}>
                            <Typography className={classes.pieTitle}>Orders Shipped Direct vs. Zone Skip</Typography>
                            <CardContent className={classes.pie}>
                                {Object.entries(shipPie).length !== 0 && (
                                    // PIE CHART
                                    <Chart options={shipPie.options} series={shipPie.series} type="donut" height="350px" />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                <Grid item xl={7} lg={12} md={12} sm={12} xs={12}>
                    <Card style={{height: "100%"}}>
                        <CardContent>
                            {/* LINE CHART */}
                            <Chart
                                options={plannerAssembly.options}
                                series={plannerAssembly.series}
                                type="line"
                                width="100%"
                                height="350px"
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid container spacing={2}>
                <Grid item md={9} sm={12} xs={12}>
                    <Card>
                        <CardContent>
                            {
                                Object.entries(thisMonth).length !== 0 && (
                                    <Chart
                                        options={thisMonth.options}
                                        series={thisMonth.series}
                                        type="area"
                                        width="100%"
                                        height="450px"
                                    />      
                                )
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                    <TableContainer component={Card} className={classes.logCard}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order Status</TableCell>
                                    <TableCell align="center">Total Order</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderStatusStats.map((o) => (
                                    <TableRow key={o.id}>
                                        <TableCell>{o.status}</TableCell>
                                        <TableCell align="center">{o.total}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}
