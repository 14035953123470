import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, makeStyles, Grid } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const useStyles = makeStyles((theme) => ({
    image: {
        backgroundImage: "url(/images/background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    containerBlock: {
        alignItems: "center",
        color: "#4d4d4d",
        maxWidth: "850px",
        margin: "0 auto",
        left: "0",
        right: "0",
        padding: "20px",
        position: "absolute",
        textAlign: "center",
        transform: "translateY(-50%)",
        top: "50%",
    },
    titleText: {
        color: "#4d4d4d",
        fontWeight: "bold",
        marginBottom: "25px",
    },
    subtitleText: {
        color: "#4d4d4d",
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function Home() {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.image}
            style={{ minHeight: "100vh" }}
        >
            <div className={classes.containerBlock}>
                <Typography component="h1" variant="h3" className={classes.titleText}>
                    Welcome to Maracle
                </Typography>
                {/* <Typography variant="h4" className={classes.subtitleText}>
                
            </Typography> */}
                <Button
                    component={Link}
                    to="/login"
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<LockOpenIcon />}
                >
                    Sign In
                </Button>
            </div>
        </Grid>
    );
}
