import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// import Alerts from "../components/Portal/Alerts/Alerts";
import Dashboard from "../components/Portal/Dashboard/Dashboard";
import Pallet from "../components/Portal/Pallet/Pallet";
import Picker from "../components/Portal/Picker/Picker";
import PrintingStation from "../components/Portal/PrintStation/PrintStation";

class PortalRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/crm/" exact component={Dashboard} />
                    {/* <Route path="/crm/alerts" exact component={Alerts} /> */}
                    <Route
                        path="/crm/bwprinting"
                        exact
                        component={() => <PrintingStation title="Black & White Printing Station" />}
                    />
                    <Route
                        path="/crm/colourprinting"
                        exact
                        component={() => <PrintingStation title="Colour Printing Station" />}
                    />
                    <Route path="/crm/picker" exact component={Picker} />
                    <Route path="/crm/pallet" exact component={Pallet} />
                </Switch>
            </div>
        );
    }
}

export default PortalRouter;
